<!-- //
文章/作品详情
@auth xuyd
@date 2022-11-01
//-->
<template>
  <div class="layout-vertical">
    <div class="layout-result p24">
      <a-form-model ref="refForm" :model="detailData" layout="vertical" class="layout-form p24">
        <a-form-model-item>
          <span slot="label" class="form-label">封面</span>
          <div class="img-preview">
            <div class="upload-img" @click="handlePreview(detailData.coverUri)">
              <img class="img" :src="ossUrl(detailData.coverUri)"/>
            </div>
          </div>
        </a-form-model-item>

        <a-form-model-item prop="title">
          <span slot="label" class="form-label">标题</span>
          <span>{{detailData.title}}</span>
        </a-form-model-item>

        <a-form-model-item prop="content">
          <span slot="label" class="form-label">正文</span>
          <div style="width: 100%; max-height:500px; overflow: auto; border: 1px solid #dddddd;padding: 10px;">
            <div v-html="detailData.content"></div>
          </div>
        </a-form-model-item>

        <a-form-model-item prop="tabList">
          <span slot="label" class="form-label">
            添加标签
          </span>
          {{ getStr(detailData.trendTab ||[]) }}
<!--          <div>-->
<!--            <div v-for="(item, index) in detailData.trendTab" :key="index">-->
<!--              {{item}}-->
<!--            </div>-->
<!--          </div>-->
        </a-form-model-item>
      </a-form-model>
    </div>

    <a-modal :visible="previewVisible" :footer="null" style="padding-top: 20px" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>

  export default {
    name: 'ArticleDetailComp',
    components: {},
    dictTypes: [],
    data() {
      return {
        previewImage: null,
        previewVisible: null,
      };
    },
    props: {
      contentType: Number,
      detailData: Object,
    },
    computed: {},
    created() {
    },
    mounted() {
    },

    methods: {
      ossUrl(uri) {
        if (uri) {
          return this.$const.OssImagePrefix + '/' + uri;
        }
        return null;
      },

      /**
       * 点击预览
       * @param file
       * @returns {Promise<void>}
       */
      handlePreview(filePath) {
        this.previewImage = null;
        let url = this.ossUrl(filePath)
        this.previewImage = url;
        this.previewVisible = true;
      },
      handleCancel() {
        this.previewVisible = false;
      },
      getStr(ary) {
        if (ary && ary.length > 0) {
          return ary.join('、');
        }
        return '暂无';
      }
    }
  }
</script>

<style lang="less">

  .ant-form-vertical .ant-form-item {
    /*padding-bottom: 0;*/
  }

  .img-preview {
    .ant-upload.ant-upload-drag .ant-upload {
      padding: 0;
      margin: 0;
    }

    .ant-upload.ant-upload-drag {
      background-color: #ffffff;
    }
  }

</style>
<style lang="less" scoped>
  .form-label {
    font-size: 16px;
    font-weight: 600;
    color: #333333;
    text-align: left;
    line-height: 24px;

    .tip {
      font-size: 12px;
      font-weight: 300;
    }
  }

  .form-value {
    min-width: 375px;
    /*max-width: 700px;*/
  }

  .layout-form {
    min-width: 700px;

    .form-left {
      width: 375px;
    }

    .img-preview {
      width: 235px;
      height: 294px;
      padding: 0;
      margin: 0;
      display: inline-block;
      text-align: center;
      background: #ffffff;

      .upload-img {
        /*width: 100%;*/
        /*height: 100%;*/
        width: 235px;
        height: 294px;
        background-color: #404040;
        cursor: pointer;

        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .upload-empty {
        width: 100%;
        height: 100%;
        display: inline;
        vertical-align: middle;
      }

      .upload-text {
        font-size: 12px;
        font-weight: 400;
        color: #000000a5;
      }

      .upload-hint {
        font-size: 12px;
        font-weight: 400;
        color: #00000072;
      }
    }

    .form-right {
      flex: 1;
      margin-left: 48px;
    }
  }

  .modal-title {
    display: flex;

    .title {
      font-weight: 600;
      font-size: 14px;
    }

    .hint {
      margin-left: 10px;
      font-weight: 400;
      font-size: 12px;
      color: #0000006d;
    }
  }
</style>
