<template>
  <ak-container card :bodyStyle="{ padding: 0 }" class="ak-card-table">
    <PageHeader :show-icon="true"
                :show-button="false"
                :show-confirm="false"></PageHeader>
    <div class="common-detail-view W100">
      <DetailTop :businessResp="businessResp" :personalResp="personalResp"/>
      <DetailContent class="">
        <template slot="title">
          <span>{{contentName}}详情</span>
        </template>
        <template slot="content">
          <ArticleDetailComp :detailData="detailData" v-if="contentType == 3 || contentType == 4"/>
        </template>
      </DetailContent>
    </div>
  </ak-container>
</template>

<script>
  import * as Content from '@/api/content/video.api'
  import DetailTop from '../../components/detail-top.vue'
  import DetailContent from '../../components/card-detail-content.vue'
  import ArticleDetailComp from '../../components/card-detail-content-article.vue'

  export default {
    name: 'ArticleDetail',
    dictTypes: [
      {
        dictType: 'contentType',
        dataType: Number
      },
      {
        dictType: 'platformType',
        dataType: Number
      }
    ],
    components: {
      DetailTop,
      DetailContent,
      ArticleDetailComp,
    },
    data() {
      return {
        id: 0,
        detailData: {
          coverUri: null,
          title: null,
          content: null,
          trendTab: [],
        },
        contentType: null,
        businessType: null,
        businessResp: {},
        personalResp: {},
      }
    },
    computed: {
      // 内容：作品，文章
      contentName() {
        return this.dict.label.contentType[this.contentType]
      }
    },
    mounted() {
      this.id = this.$route.query.id
      this.contentType = this.$route.query.contentType
      this.businessType = this.$route.query.businessType
      this.queryData()
    },
    methods: {
      queryData() {
        Content.detail(this.id).then(res => {
          console.log("-----detail----->", res.data)
          this.detailData = res.data || {}
          this.businessResp = res.data.businessResp || {}
          this.personalResp = res.data.personalResp || {}
        }).finally(() => {
        })
      }
    }
  }
</script>
<style>
  .create-bk-card {
    margin: 20px 0 20px 0;
  }

  .bk_creater {
    width: 100%;
    height: 400px;
    background: rgb(237, 240, 245) url(../../../assets/bk_creater.png) 0 0 no-repeat;
  }

  .bussiness {
    margin-top: 20px;
  }

  .fileList .ak-viewer-wrap .preview-thumbnail-list .list-item img {
    width: 300px !important;
    height: 400px !important;
  }
</style>>
<style lang="less" scoped>
  .common-detail-view {
    .header-info-main {
      padding: 20px;

      .C666 {
        display: inline-block;
        width: 90px;
        text-align: right;
      }
    }
  }

  .left-detail-card {
    width: 100%
  }

  /deep/ .bindUser .ant-card-body {
    padding: 12px 0 6px 24px;
  }

  .user-info {
    margin-top: 20px;

    .user-info-content {
      padding: 20px;

      .user-info-certification {
        font-weight: bold;
        margin-bottom: 16px;
      }
    }
  }

  .ant-col {
    margin-bottom: 8px;
  }

  .ant-col:last-child {
    margin-bottom: 0;
  }

  // .ant-row{
  //   margin-bottom: 8px;
  // }
  .ant-row:last-child {
    margin-bottom: 0;
  }
</style>
